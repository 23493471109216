import React from "react"
import styles from "./NewReleaseModal.module.scss"
import Modal from "components/UI/elements/Modal"
import Button from "components/UI/elements/Button/Button"
import { useModifyUser } from "resources/user/userQueries"
import { useFetchCurrentUser } from "resources/user/currentUserQueries"
import IconButton from "components/UI/elements/IconButton/IconButton"
import funnels from "./funnels.jpg"

const CURRENT_NEW_FEATURE = "funnels"

export default function NewReleaseModal() {
  const { data: currentUser } = useFetchCurrentUser()
  const { mutate: modifyUser } = useModifyUser()

  const { id, frontend_settings } = currentUser!

  const handleClose = () => {
    modifyUser({
      id,
      data: {
        frontend_settings: {
          ...(frontend_settings ?? {}),
          lastReleaseModalShown: CURRENT_NEW_FEATURE,
        },
      },
    })
  }

  const { lastReleaseModalShown } = frontend_settings ?? {}

  // @ts-ignore
  if (window.Cypress) return null

  return (
    <Modal
      open={lastReleaseModalShown !== CURRENT_NEW_FEATURE}
      customHeader={
        <header>
          <h3>🎉 Check out the updates:</h3>
          <IconButton
            color="grey"
            icon={["fas", "times"]}
            tooltip="Close"
            variant="transparent"
            onClick={handleClose}
            data-testid="close-button"
          />
        </header>
      }
      handleClose={handleClose}
      className={styles.modal}
      contentClassName={styles.content}
    >
      <h3>Unleash the Power of Funnels 🚀</h3>

      <img src={funnels} alt="" />

      <p>
        We are thrilled to introduce Funnels, a powerful no-code analytical feature designed to
        track and analyze customers' journeys from start to finish.
      </p>

      <p>
        <b>Visualise your customer journey:</b> Effortlessly track the path your customers take
        through a sequence for use cases such as completing a purchase, signing up for a service, or
        navigating through a multi-step process.
      </p>

      <p>
        <b>Filter and compare behaviours of different customer segments:</b> Create charts on
        various attributes to see flexible and precise analysis of your target that allows you to
        investigate and pinpoint changes in the website or customer journey that impact conversion
        rates.
      </p>

      <p>
        <b>Gain insights into how customers interact with your brand:</b> Investigate the ways in
        which customers engage with your brand across various devices and touchpoints, including
        their behaviours, preferences, and engagement patterns.
      </p>

      <p>
        <b>Manage access control:</b> Set the right people to see and edit the right charts. Give
        permission for viewing or editing charts, so your team can work together safely and
        effectively.
      </p>

      <p>
        Learn more about Funnels and their possibilities in our{" "}
        <a
          href="https://docs.meiro.io/books/meiro-business-explorer/page/funnels-vF6"
          target="_blank"
          rel="noreferrer"
        >
          documentation
        </a>
        .
      </p>

      <div className={styles.buttonWrapper}>
        <Button size="md" onClick={handleClose}>
          Got it
        </Button>
      </div>
    </Modal>
  )
}
